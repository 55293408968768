import {EventPostProps} from "../EventPost";

/**
 * ホーム画面左下に表示
 * （formatted_dateに従い「次回」か「過去」か自動切り替え表示）
 */
export const nextEventProps: EventPostProps = {
    post: { event_id: '20250511'}
};
/**
 * ホーム画面右下に表示
 * （formatted_dateに従い「次回」か「過去」か自動切り替え表示）
 */
export const pastEventProps: EventPostProps = {
    post: { event_id: '20250119'}
};


export const sidebar = {
    title: '',
    description: '',
    archives: [
        {title: 'onken21.web.master@gmail.com', url: '#'},
    ],
};

export class HomeImageProps {
    imageUrl: string;

    adjustOffset_xs: number;
    adjustOffset_sm: number;
    adjustOffset_md: number;
    low_position: boolean;

    constructor(imageUrl: string,
                adjustOffset_xs: number,
                adjustOffset_sm: number,
                adjustOffset_md: number,
                low_position: boolean,
    ) {
        this.imageUrl = imageUrl;
        this.adjustOffset_xs = adjustOffset_xs;
        this.adjustOffset_sm = adjustOffset_sm;
        this.adjustOffset_md = adjustOffset_md;
        this.low_position = low_position;
    }
}

export class HomeImageTitlePositionProps {
    low_position: boolean;
    constructor(low_position: boolean,
    ) {
        this.low_position = low_position;
    }
}

export const homeImagesProps: HomeImageProps[] = [

    // 20140412
    //new HomeImageProps('./old_contents/images_event/20140412/IMG_7037.jpg', 45, -50,73), // 0
    new HomeImageProps('./old_contents/images_event/20140412/IMG_1555.jpg', 45, 0,73, true), // 0
    new HomeImageProps('./old_contents/images_event/20140412/IMG_1491.jpg', 0, 0,73, true), // 1
    new HomeImageProps('./old_contents/images_event/20140412/IMG_1405.jpg', 45, 0,0, true), // 2
    new HomeImageProps('./old_contents/images_event/20140412/IMG_7130.jpg', 45, -120,-300, false), // 3
    new HomeImageProps('./old_contents/images_event/20140412/IMG_1211.jpg', 45, -50,0, true), // 4
    new HomeImageProps('./old_contents/images_event/20140412/IMG_6961.jpg', 45, -120,-300, false), // 5
    // 20150411
    new HomeImageProps('./old_contents/images_event/20150411/IMG_2520.jpg', 45, 0,70, true), // 6
    new HomeImageProps('./old_contents/images_event/20150411/IMG_2503.jpg', 45, -80,0, true), // 7
    new HomeImageProps('./old_contents/images_event/20150411/IMG_2675.jpg', 5, -150,-200, true), // 8
    new HomeImageProps('./old_contents/images_event/20150411/IMG_2618.jpg', 45, -150,-400, false), // 9
    new HomeImageProps('./old_contents/images_event/20150411/IMG_2536.jpg', 45, -100,-300, false), // 10
    // 20100503
    new HomeImageProps('./old_contents/images_event/20100503/IMG_184.jpg', 45, -100,-300, false), // 11
    new HomeImageProps('./old_contents/images_event/20100503/IMG_114.jpg', 15, -50,20, true), // 12
    new HomeImageProps('./old_contents/images_event/20100503/IMG_27.jpg', 10, -50,20, true), // 13
    new HomeImageProps('./old_contents/images_event/20100503/IMG_11.jpg', 45, -180,-200, false), // 14
    // 19930110
    new HomeImageProps('./old_contents/images_event/19930110/19930110_image_01.jpg', 20, 40,10, true), // 15
    // 20190427
    new HomeImageProps('./old_contents/images_event/20190427/IMG_0716.jpg', -20, -350,-350, false), // 16
    new HomeImageProps('./old_contents/images_event/20190427/IMG_0934.jpg', 0, -350,-450, false), // 17
    new HomeImageProps('./old_contents/images_event/20190427/IMG_2215.jpg', -20, -150,-150, true), // 18
    new HomeImageProps('./old_contents/images_event/20190427/IMG_2200.jpg', 48, -80,72, true), // 19
    new HomeImageProps('./old_contents/images_event/20190427/IMG_2559.jpg', 48, -100,-160, false), // 20
    // 20180414
    new HomeImageProps('./old_contents/images_event/20180414/IMG_0005.jpg', 0, -450,-450, false), // 21
    new HomeImageProps('./old_contents/images_event/20180414/IMG_0043.jpg', 48, -150,-100, false), // 22
    new HomeImageProps('./old_contents/images_event/20180414/IMG_0044.jpg', 48, 48,70, true), // 23
    new HomeImageProps('./old_contents/images_event/20180414/IMG_0776.jpg', 0, -300,-350, false), // 24
    new HomeImageProps('./old_contents/images_event/20180414/IMG_0361.jpg', -20, -350,-450, false), // 25
    new HomeImageProps('./old_contents/images_event/20180414/IMG_0469.jpg', -10, -400,-400, false), // 26
    new HomeImageProps('./old_contents/images_event/20180414/IMG_0705.jpg', 0, -100,-100, true), // 27
    new HomeImageProps('./old_contents/images_event/20180414/IMG_1030.jpg', -10, -400,-400, false), // 28
    // 20170513
    new HomeImageProps('./old_contents/images_event/20170513/IMG_3896.jpg', -10, -400,-450, false), // 29
    new HomeImageProps('./old_contents/images_event/20170513/IMG_3940.jpg', 40, -250,-250, false), // 30
    new HomeImageProps('./old_contents/images_event/20170513/IMG_3964.jpg', -20, -450,-450, false), // 31
    new HomeImageProps('./old_contents/images_event/20170513/IMG_4007.jpg', -20, -350,-450, false), // 32
    new HomeImageProps('./old_contents/images_event/20170513/IMG_4056.jpg', -10, -200,-250, true), // 33
    new HomeImageProps('./old_contents/images_event/20170513/IMG_9272.jpg', -20, -200,-200, false), // 34
    // 20060415
    new HomeImageProps('./old_contents/images_event/20060415/IMG_5424.jpg', 10, -300,-350, false), // 35
    new HomeImageProps('./old_contents/images_event/20060415/IMG_5501.jpg', 40, 0,50, true), // 36
    new HomeImageProps('./old_contents/images_event/20060415/IMG_5510.jpg', 10, -50,-100, true), // 37
    new HomeImageProps('./old_contents/images_event/20060415/IMG_5626.jpg', 40, 0,0, true), // 38
    new HomeImageProps('./old_contents/images_event/20060415/IMG_5852.jpg', 49, 50,50, true), // 39
    // 20060408
    new HomeImageProps('./old_contents/images_event/20060408/IMG_4904.jpg', -20, -100,-150, true), // 40
    new HomeImageProps('./old_contents/images_event/20060408/IMG_4974.jpg', 30, 0,0, true), // 41
    new HomeImageProps('./old_contents/images_event/20060408/IMG_5001.jpg', 15, -200,-250, false), // 42
    new HomeImageProps('./old_contents/images_event/20060408/IMG_5157.jpg', 49, 50,50, true), // 43
    new HomeImageProps('./old_contents/images_event/20060408/IMG_5279.jpg', 49, 50,50, true), // 44
    new HomeImageProps('./old_contents/images_event/20060408/IMG_5362.jpg', 49, 50,50, true), // 45
    // 20120318
    new HomeImageProps('./old_contents/images_event/20120318/IMG_4096.jpg', 20, -60,-100, true), // 46
    new HomeImageProps('./old_contents/images_event/20120318/IMG_4146.jpg', 0, -200,-450, false), // 47
    new HomeImageProps('./old_contents/images_event/20120318/IMG_4255.jpg', 48, 0,72, true), // 48
    new HomeImageProps('./old_contents/images_event/20120318/IMG_4469.jpg', 49, 30,70, true), // 49
    new HomeImageProps('./old_contents/images_event/20120318/IMG_4321.jpg', 40, 48,72, true), // 50

];
