import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {getConcert, getOpera} from "./data_event/onken21_event_detail";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {ExternalLink} from "./data_event/EventOperaProps";

export interface EventPostProps {
    post: {
        event_id: string;
    };
}

export default function EventPost(props: EventPostProps) {
    const {post} = props;
    const [open, setOpen] = React.useState(false);
    const [fullWidth, /*setFullWidth*/] = React.useState(true);
    const [maxWidth, /*setMaxWidth*/] = React.useState<DialogProps['maxWidth']>('lg');
    const currentDate = new Date();
    const noteStyle = {color: "#ff69b4", fontWeight: 'bold'};
    let eventOperaProp = getOpera(post.event_id);
    let eventConcertProp = getConcert(post.event_id);
    let eventFormattedDate = new Date();
    let eventPrintDate = '';
    let largeFlyerPathBase = './old_contents/flyers/';
    let smallFlyerPathBase = './old_contents/flyers/';
    let largeFlyerPath = "";
    let smallFlyerPath = "";
    let work_title = '';
    let event_time1 = '';
    let event_place = '';
    let event_station = '';
    let ext_links: Array<ExternalLink> = [];

    if (eventOperaProp) {
        eventFormattedDate = new Date(eventOperaProp.formatted_date);
        eventPrintDate = eventOperaProp.event_date;
        work_title = eventOperaProp.work_title;
        event_time1 = eventOperaProp.event_time1;
        event_place = eventOperaProp.event_place;
        event_station = eventOperaProp.event_station;
        largeFlyerPath = largeFlyerPathBase + eventOperaProp.flyer_large_file;
        smallFlyerPath = smallFlyerPathBase + eventOperaProp.flyer_small_file;
        ext_links = eventOperaProp.ext_links;
    } else if (eventConcertProp) {
        eventFormattedDate = new Date(eventConcertProp.formatted_date);
        eventPrintDate = eventConcertProp.event_date;
        work_title = eventConcertProp.work_title;
        event_time1 = eventConcertProp.event_time1;
        event_place = eventConcertProp.event_place;
        event_station = eventConcertProp.event_station;
        largeFlyerPath = largeFlyerPathBase + eventConcertProp.flyer_large_file;
        smallFlyerPath = smallFlyerPathBase + eventConcertProp.flyer_small_file;
        ext_links = eventConcertProp.ext_links;
    } else {
        // どちらにも存在しないのは、データの設定がおかしい。
        console.warn('EventPost: event_idがどちらにも存在しないのは、データの設定がおかしい。');
    }
    let isPastEvent = (currentDate > eventFormattedDate);
    //console.log(`eventPrintDate:${eventPrintDate}, eventFormattedDate:${eventFormattedDate}, isPastEvent:${isPastEvent}`);
    const event_time = isPastEvent ? '過去の公演' : '次回の公演';
    const fixedMessage = isPastEvent ? '多くの方のご来場、誠にありがとうございました。' : 'ご来場を心よりお待ち申し上げます。';
    const onClickEndHandler = () => {
        handleClickOpen();
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid item xs={12} sm={6} md={6}>
            <CardActionArea component="a" href="#" onClick={onClickEndHandler}>
                <Card sx={{display: 'flex', boxShadow: 5}}>
                    <CardContent sx={{flex: 1}}>
                        <Typography variant="h6" paragraph sx={{pb: 0}}>
                            <span><img src={"./old_contents/heart.gif"} alt={''}/> {event_time}</span>
                        </Typography>
                        <Typography variant="h5" sx={{pl: 0, fontWeight: 'bold'}}>
                            {work_title}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" sx={{pl: 0}}>
                            {eventPrintDate} - {event_time1}
                        </Typography>
                        <Typography variant="subtitle2" sx={{pl: 0}}>
                            {event_place}
                        </Typography>
                        <Typography paragraph variant="subtitle2" sx={{pl: 0}}>
                            （{event_station}）
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" sx={{fontWeight: 'bold'}}>
                            {fixedMessage}
                        </Typography>
                    </CardContent>
                            <Box sx={{margin: 'auto', pr: 4}}>
                                <CardMedia
                                    component="img"
                                    sx={{boxShadow: 5, maxWidth: 200, maxHeight: 150, display: {xs: 'none', sm: 'none', md: 'block'}}}
                                    image={smallFlyerPath}
                                    alt={'no image !!!'}
                                />
                            </Box>
                </Card>
            </CardActionArea>
            {/* large image modal dialog */}
            <div>
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContent>
                        <Box sx={{margin: 'auto', pr: 4, width: 'fit-content'}}>
                            <img
                                src={largeFlyerPath}
                                alt={'no image'}
                                loading="lazy"
                            />
                        </Box>
                    </DialogContent>
                    { /* 最初の1つのみ */
                        (ext_links.length == 0) ? <div /> :
                            <Typography variant="subtitle1" paragraph sx={{pl: 4}}>
                                <span style={noteStyle}>♪</span>　
                                <a href={ext_links[0].link_url} target="_blank">{ext_links[0].link_title} </a>
                            </Typography>
                    }
                    <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                            閉じる
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        </Grid>
    );
}
